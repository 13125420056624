



























































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { currencyFormat , numericOnly , changeCurrencytoNumeric} from "@/validator/globalvalidator";
import { PropsModel } from "@/models/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
export default Vue.extend({
  props: [
    "checkboxOrRadio",
    "handleDatePicker",
    "dataSource",
    "columns",
    "defaultPagination",
    "bordered",
    "scroll",
    "selectedRowKeys",
    "onSelectChange",
    "handleInput",
    "handleInputNumber",
    "handleDate",
    "handleSwitch",
    "handleDateMonth",
    "handleCheckBox",
    "handleSelect",
    "handleSearchSelectTable",
    "paginationcustom",
    "loading",
    "isClick",
    "footerRight",
    "footerStockValuasi",
    "expandedRowRender",
    "getCheckboxProps"
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      formatNumeric:numericOnly,
      formatCurrency:currencyFormat,
      formatCurrencytoNumber:changeCurrencytoNumeric,
      responsiveColCheckBox: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColInput: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColInputNumber: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColDate: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColSwitch: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColDateMonth: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColRangePicker: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColSelect: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveMenu: [],
      resultbutton: "",
      // buttonOneOperation: '',
      searchText: "",
    };
  },
  computed: {
    // optionsSelect(index:number) {
    //   return this.responsiveColSelect[index].options;
    // },
    stateResponsiveColSelect() {
      return this.responsiveColSelect;
    },
  },
  mounted() {
    this.responsiveColCheckBox = this.columns.find(
      (data) => data.responsiveColCheckBox
    )?.responsiveColCheckBox;
    this.responsiveColInput = this.columns.find(
      (data) => data.responsiveColInput
    )?.responsiveColInput;
    this.responsiveColInputNumber = this.columns.find(
      (data) => data.responsiveColInputNumber
    )?.responsiveColInputNumber;
    this.responsiveColDate = this.columns.find(
      (data) => data.responsiveColDate
    )?.responsiveColDate;
    this.responsiveColSwitch = this.columns.find(
      (data) => data.responsiveColSwitch
    )?.responsiveColSwitch;
    this.responsiveColDateMonth = this.columns.find(
      (data) => data.responsiveColDateMonth
    )?.responsiveColDateMonth;
    this.responsiveColRangePicker = this.columns.find(
      (data) => data.responsiveColRangePicker
    )?.responsiveColRangePicker;
    this.responsiveColSelect = this.columns.find(
      (data) => data.responsiveColSelect
    )?.responsiveColSelect;
    if (this.columns.find((data) => data.dataIndex === "operation")) {
      this.responsiveMenu = this.columns.find(
        (data) => data.dataIndex === "operation"
      ).responsiveMenu;
      this.resultbutton = this.columns.find(
        (data) => data.dataIndex === "operation"
      ).button;
    }
    if(!this.responsiveColCheckBox) this.responsiveColCheckBox = [];
    if(!this.responsiveColInputNumber) this.responsiveColInputNumber = [];
    if(!this.responsiveColDate) this.responsiveColDate = [];
    if(!this.responsiveColSwitch) this.responsiveColSwitch = [];
    if(!this.responsiveColDateMonth) this.responsiveColDateMonth = [];
    if(!this.responsiveColRangePicker) this.responsiveColRangePicker = [];
    if(!this.responsiveColSelect) this.responsiveColSelect = [];
    if(!this.responsiveColInput) this.responsiveColInput = [];
  },
  methods: {
    onkeypress (evt, obColInput) {
      const charCode = evt.keyCode;
      const column = ["rate", "qtyTotal", "qtyPhysical", "qtyDifference", "qtyAdjustment" , "fromQty" , "toQty" , "price" , "conversionRate" , "debit" , "credit" ,"unitPrice","qty" , "taxInvoiceSerialNumberFrom", "taxInvoiceSerialNumberTo"]; // define which column need to add validation rules
      if (obColInput.name) {
        // only allow number and decimal
        if (column.includes(obColInput.name) && (charCode != 46 && charCode != 45 && charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        }
      }
    },
    handleClickQrCode (value, record) {
      let params = {
        value,
        record
      };
      this.$emit("on-clickQr", params);
    },
    customRow(record) {
      return {
        on: {
          click: (e) => {
            if (!e.target.dataset.DOMStringMap)
              this.$emit("on-clickrow", record);
          },
        },
      };
    },
    // handle search select
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // handle search setiap columns
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    onDelete(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$confirm({
        title: "Are you sure want to delete this item?",
        onOk: () => {
          this.$emit("on-delete", params);
        },
        onCancel() {return;}
      });
    },
    onDetail(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-detail", params);
    },
    onEdit(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-edit", params);
    },
    onView(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-view", params);
    },
    onSave(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-save", params);
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    handleTableChange(pagination, filters, sorter): void {
      const params = {
        sort: sorter,
        index: this.idtable,
        pagination,
        filters
      };
      this.$emit("on-tablechange", params);
    },
    handleOptionSelect(index, record, currentNameColumn):PropsModel[] {
      let tempOption = null;
      if(record.optionCustom) {
        record.optionCustom.forEach((dataLoop) => {
          if(currentNameColumn === dataLoop.name) {
            tempOption = dataLoop.option;
          }
        });
      } 
      
      if(tempOption) {
        return tempOption;
      } else {
        return this.responsiveColSelect[index].options;
      }
    },
    handleClickColumn (record, value) {
      let objColumnNameValue;
      for (const key in record) {
        if(record[key] === value) {
          objColumnNameValue = {
            column: key,
            value: record[key]
          };
        }
      }
      this.$emit("on-columnClicked", record, objColumnNameValue);
    },
    handleClickColumnArray (record, data, value) {
      let objColumnNameValue;
      for (const key in record) {
        if(Array.isArray(record[key]) && record[key].length === value.length && record[key].every(function(dataEvery, index) { return dataEvery === value[index];})) {
          objColumnNameValue = {
            column: key,
            value: data, // value change with data
            dataColumn: value
          };
        }
      }
      this.$emit("on-columnClickedArray", record, objColumnNameValue);
    }
  },
});
